import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulGroupPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import BoxLayout from 'components/UI/Layouts/BoxLayout'
import Description from 'components/UI/Description'
import HeroCompany from 'components/AboutUs/CompanyPurpose'
import ManagementList from 'components/AboutUs/Management'
import MiniPictures from 'components/UI/MiniPictures'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import Title from 'components/UI/Title'

const AboutUs = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    metaTitle,
    metaDescription,
    title,
    surtitle,
    companyPurposeTitle,
    longDescription,
    parallaxMediaType,
    image,
    section1,
    section2,
    bottomBox,
  } = R.pathOr(null, ['contentfulAboutUsV2'], data)

  const {
    primaryText: {primaryText: section1PrimaryText = ' '},
    secondaryText: {secondaryText: section1SecondaryText = ' '},
  } = section1

  const {
    primaryText: {primaryText: section2PrimaryText = ' '},
    secondaryText: {secondaryText: section2SecondaryText = ' '},
  } = section2

  const {
    primaryText: {primaryText: bottomBoxPrimaryText = ' '},
    secondaryText: {secondaryText: bottomBoxSecondaryText = ' '},
    cta: bottomBoxCta = ' ',
    ctaLink: bottomBoxCtaLink = ' ',
  } = bottomBox

  const {
    contentsSections1: cards,
    tooltipHumanData: {tooltipHumanData},
    tooltipInspirationalReality: {tooltipInspirationalReality},
    tooltipSimplexFuture: {tooltipSimplexFuture},
  } = R.pathOr(null, ['contentfulAboutUsV2', 'companyPurpose'], data)

  const managementTeams = R.pathOr(
    null,
    ['contentfulCountry', 'managementTeams'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  const instaIcon = R.pathOr(
    null,
    ['contentfulSocialMediaShare', 'icon', 'file', 'url'],
    data,
  )

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <HeroCompany
        hat={surtitle}
        title={title}
        longDescription={longDescription}
        parallaxMediaType={parallaxMediaType}
        image={image}
      />
      <Section hasSmallPadding>
        <Title variant="h2" type="mediumTitle">
          {section1PrimaryText}
        </Title>
        <Description>{section1SecondaryText}</Description>
      </Section>
      {/* <KeyplaySection title={'title'} content={'content'}></KeyplaySection> */}
      <Section id="company-purpose" hasSmallPadding>
        <Title variant="h2" type="mediumTitle">
          {companyPurposeTitle}
        </Title>
        <MiniPictures
          cards={cards}
          tooltipHumanData={tooltipHumanData}
          tooltipInspirationalReality={tooltipInspirationalReality}
          tooltipSimplexFuture={tooltipSimplexFuture}
          hasNoTooltip
        />
      </Section>
      <Section hasSmallPadding hasGreyBackground>
        <Title isCentered variant="h2" type="mediumTitle">
          {section2PrimaryText}
        </Title>
        <Description>{section2SecondaryText}</Description>
      </Section>
      {managementTeams && (
        <ManagementList
          managementTeams={managementTeams}
          instaIcon={instaIcon}
        />
      )}
      <Section hasSmallPadding>
        <BoxLayout>
          <Title type="subTitle">{bottomBoxPrimaryText}</Title>
          <Description hasMarginBottom>{bottomBoxSecondaryText}</Description>
          <div style={{marginTop: 16}}>
            <RoundButton
              href={bottomBoxCtaLink}
              arrow
              isCentered
              isNowrap
              color="secondary"
              externalLink
            >
              {bottomBoxCta}
            </RoundButton>
          </div>
        </BoxLayout>
      </Section>
    </>
  )
}

AboutUs.propTypes = {
  data: shape({
    allContentfulCommittedKeyrus: allContentfulGroupPropTypes,
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}
export default AboutUs

export const pageQuery = graphql`
  query templateAboutUs(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulAboutUsV2(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      title
      surtitle
      slug
      metaTitle
      metaDescription {
        metaDescription
      }
      shortDescription {
        shortDescription
      }
      longDescription {
        raw
      }
      parallaxMediaType
      video {
        file {
          url
        }
      }
      image {
        gatsbyImageData
        file {
          url
        }
      }
      companyPurpose {
        callToAction
        contentsSections1 {
          image {
            file {
              url
              contentType
            }
          }
          title
          variation
          description {
            raw
          }
        }
        slug
        tooltipHumanData {
          tooltipHumanData
        }
        tooltipInspirationalReality {
          tooltipInspirationalReality
        }
        tooltipSimplexFuture {
          tooltipSimplexFuture
        }
      }
      companyPurposeTitle
      section1 {
        ...contentFields
      }
      section2 {
        ...contentFields
      }
      bottomBox {
        ...contentFields
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
      managementTeams {
        id
        name
        pageTitle
        managers {
          id
          jobTitle
          linkedInProfile
          name
          photo {
            gatsbyImageData(placeholder: BLURRED)
          }
          biography {
            raw
          }
          showBiography
        }
      }
    }
    contentfulSocialMediaShare(name: {regex: "/LinkedIn/"}) {
      icon {
        file {
          url
        }
      }
    }
  }
`
